.login-container .head-2 {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 2rem;
}

.login-container .subtitle {
  color: #95989a!important;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.125rem;
}

.LoginForm{
  max-width: 24.1875rem;
  margin: 0 auto;
  font-size: .75rem;
}


  .login-input.form-control {
    border: 1px solid #bbbdbf;
    border-radius: .25rem;
    background-color: #fff;
    font-size: .9375rem;
    line-height: 3.125rem;
    height: 3.125rem;
    font-weight: 400;
    padding-left: .625rem;
    color: #444;
}

.custom-effect-label.active {
  top: .0625rem;
  font-size: .625rem;
  z-index: 10;
  width: auto;
  padding: 0;
}

.custom-effect-label {
  position: absolute;
  left: .6875rem;
  top: .3125rem;
  font-size: 1rem;
  z-index: 1;
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  color: #95989a;
  width: 95%;
  padding: .5rem 0;
  cursor: text;
}

.input-group {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: stretch;
  align-items: stretch;
  width: 100%;
}

.margin-bottom-20 {
  margin-bottom: 1.25rem;
}

.responsive-new-user {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: .25rem;
  margin-top: .25rem;
  font-size: .875rem;
  -webkit-align-items: center;
  align-items: center;
}

.login-container form[name=LoginForm] .btn-primary {
  border: 1px solid #ed008b;
  background-color: #ed008b;
  width: 100%;
  height: 3.125rem;
  border-radius: 2.5rem;
}

.margin-top-30 {
  margin-top: 30px;;
}

.login-container .logo-navbar {
  height: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.login-container{
  top: 35% !important;
  left: 48% !important;
}

.mb-10{
  margin-bottom: 10px !important;;
}

.error{
  color: red;
}

.loginColumn{
  margin-left: 25px;;
}

.new-user-reset , .forget-password-link{
  color: #5586ef !important;
  cursor: pointer;
  font-weight: 600;
}

.logo-auth {
  margin-top: 6.875rem;
  height: 3.90625rem;
}

.new-user-title{
  font-weight: 700;
  line-height: 2rem;
  font-size: 1.875rem;
  color: #1f124f;
}

.margin-top-50 {
  margin-top: 3.125rem;
}

.container {
  max-width: 59.25rem;
}

.form-wrapper {
  width : 60%;
  margin: 0 auto;
}

.signupForm{
  margin: 0 auto;
  margin-top: 30px;
}

.p10 {
  padding-top: 20px;;
}

.mlogo {
  width: 61%;
    text-align: center;
    margin: 0 auto;
}

.new-user-title{
  text-align: center;
}

.text-right {
  text-align: right;
}

.mb-20{
  margin-bottom: 20px;
}

.text-input {
  border-radius: 0px;
}

.signup-btn {
  border: 1px solid #ed008b;
  background-color: #ed008b !important;
  width: 100%;
  height: 3.125rem;
  border-radius: 2.5rem;
}

.signup-btn:hover {
 background-color: #d51485 !important;
}

.primary-cancel {
    border: 1px solid #ed008b !important;
    background-color: white !important;
    color: #ed008b !important;
    width: 100%;
    height: 3.125rem !important;
    border-radius: 2.5rem !important;
}

.primary-cancel:hover ,.primary-cancel:active {
  border: 1px solid #d51485 !important;
  background-color: white !important;
  color: #ed008b;
}

.loader-box {
  margin-top: 10px;;
}

.error-message{
  font-size: 14px;;
  color: #dc3545 !important;
  float: left;
    text-align: justify;
}

.form-group {
  margin-bottom: 1rem !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.member-search-form {
  text-align: center;
  padding: 25px;
  border-radius: 5px;
}

.form-container {
  padding: 12px 0 !important;
}

 .subtitle {
  color: #95989a!important;
  margin-top: 15px;;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.125rem;
  font-family: Regular-Medium,sans-serif;
}
.margin-bottom-25 {
  margin-bottom: 1.1625rem;
}

.success-text{
  color: green;
    padding: 15px;
    margin: 10px;
    width: 480px;
    margin: 0 auto;
}

.danger-message {
  padding: 10px;
  color: #c95757;
  width: 400px;
    margin: 0 auto;
}