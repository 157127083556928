.categoriesChecklistContainer {
    margin-top: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding-top: 20px;
    padding-bottom: 15px;
    padding-right: 30px !important;
}

.selectAllIndicatorsRow {
    float: right;
}

.listGroupItem {
    margin-bottom: 10px;
    border-top-width: 1px !important;
}

.scrollableCol {
    overflow-y: scroll;
}

.maxHeight {
    max-height: 400px;
}

.list-group-item.active {
    z-index: 0 !important;
}
