.fieldRow{
  display: flex;
}

.processDate{
  width: 137px !important;
}

.span-label{
  width: 100% !important;
}

.more-label {
  margin-top: 20px;;
}

.SearchButtonBox{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.closedMessage {
  padding-left: 12px;
  font-size: 12px;
}

#getClaimsHeaderRow {
  padding-left: 2%;

  .buttonSize {
    width: 170px;
  }

  .buttonSizeSm {
    width: 140px;
  }

  .headerItemCol {
    padding: 0px;
  }
}