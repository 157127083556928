.headerOptions {
    margin-bottom: 4%;
    display: flex;
    justify-content: center;
    align-items: center;

    .headerOptionsRow {
        width: 100%;
        align-items: center;
    }
}

Button.optionsButton {
    width: 170px;
    background-color: #ec008c !important;
    border: solid 2px #ec008c !important;
}

Label.text-label-options {
    float: right;
    margin-top: 10px;
}