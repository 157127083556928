// TO DO: REMOVE !IMPORTANT FLAGS

.modalBodyClaimModal {
    padding: 30px !important;
}

.requiredFieldsRow {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.confirmButtonsRow {
    justify-content: space-evenly;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 50px !important;
}

.confirmButtonsCol {
    display: "flex" !important;
    justify-content: "center" !important;
}

.confirmButtons {
    width: 50% !important;
    border-radius: 10% !important;
}

